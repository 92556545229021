import React, { useState, useEffect } from 'react';
import AuthService                    from 'services/auth.service';
import LuckyDrawService               from 'services/lucky-draw.service';
import dingSound                      from 'assets/audio/ding-short.mp3';

import SignOut                        from './SignOut';
import spinningSound                  from 'assets/audio/lucky-draw.mp3';
import impossibru                     from 'assets/audio/mission-impossible.mp3';
import Confetti                       from 'react-confetti'

export default function Prizes({tnbDraw}) {
// Back navigation, better to just reload everything.
  const backToList = () => {
    window.location.replace('/');
  }

// Get all prizes via login info.
  const { innerWidth: width, innerHeight: height }  = window;

// Random Contestants.
  const [randomContestants, setRandomContestants] = useState([]);
  const [randomMainContestants, setRandomMainContestants] = useState([]);
  const [showFirstLoader, setShowFirstLoader]     = useState(true);
  const storedRandom                              = JSON.parse(localStorage.getItem('random-contestants'));
  const storedRandomMain                          = JSON.parse(localStorage.getItem('random-main-contestants'));

// List of prizes.
  const [showPrizes, setShowPrizes]             = useState(true);
  const [prizes, setPrizes]                     = useState([]);
  const [consolationPrize, setConsolationPrize] = useState([]);

  useEffect(() => {
    setShowFirstLoader(true);
    if (tnbDraw.id) {
      LuckyDrawService.prizes(tnbDraw.id).then((data) => {
        setPrizes(data.prizes);
      }, (error) => {
        console.log(error);

        if (error.response.status === 401) {
          AuthService.logout();
          window.location.replace('/eb/login');
        }
      });

      if(storedRandom && storedRandom.length > 0){
        setRandomContestants(storedRandom);
      }else{
        LuckyDrawService.contestants(tnbDraw.id, false).then((data) => {
          let mappedContestants = data.contestants.map((hash) => {
            return { name: hash.name, id: hash.emp_id };
          });
    
          localStorage.setItem('random-contestants', JSON.stringify(mappedContestants));
          setRandomContestants(mappedContestants);
        }, (error) => {
          console.log(error);
    
          if (error.response.status === 401) {
            AuthService.logout();
            window.location.replace('/eb/login');
          }
        });
      }

      if(storedRandomMain && storedRandomMain.length > 0){
        setRandomMainContestants(storedRandomMain);
      }else{
        LuckyDrawService.contestants(tnbDraw.id, true).then((data) => {
          let mappedContestants = data.contestants.map((hash) => {
            return { name: hash.name, id: hash.emp_id };
          });
    
          localStorage.setItem('random-main-contestants', JSON.stringify(mappedContestants));
          setRandomMainContestants(mappedContestants);
        }, (error) => {
          console.log(error);
    
          if (error.response.status === 401) {
            AuthService.logout();
            window.location.replace('/eb/login');
          }
        });
      }
    }
  }, [tnbDraw]);

  useEffect(() => {
    if (randomContestants.length > 0 && randomMainContestants.length > 0){
      setShowFirstLoader(false);
    }
  }, [randomContestants, randomMainContestants]);

// Displayed prizes.
  const [displayedPrizes, setDisplayedPrizes] = useState(<div></div>);
  const [selectedPrize, setSelectedPrize]     = useState(null);
  const [showIndividual, setShowIndividual]   = useState(false);
  const [showLoader, setShowLoader]           = useState(true);
  const [theWinners, setTheWinners]           = useState([]);

  useEffect(() => {
    const clickPrizes = (hash) => {
      setSelectedPrize(hash);
      setShowPrizes(false);
      setShowIndividual(true);

      LuckyDrawService.winners(tnbDraw.id, hash.id).then((data) => {
        setShowLoader(false);

        setTheWinners(data.map((hash) => {
          return { name: hash.name, id: hash.emp_id };
        }));
      }, (error) => {
        console.log(error);

        if (error.response.status === 401) {
          AuthService.logout();
          window.location.replace('/eb/login');
        }
      });
    }

    setDisplayedPrizes(prizes.map((hash, index) => {
      return(
        <div key={ hash.name } className="position-relative mx-6 clickable" onClick={() => clickPrizes(hash) } style={{height: "25em"}}>
          { !hash.label ? '' :
            <p className="font-nunito bg-purple text-white fweight-800 font-size-7 rounded-circle py-2 shadow position-absolute d-flex align-items-center justify-content-center shadow" style={{ marginTop: '-0.5em', width: '5em', height: '5em', marginLeft: '-2em' }}>{ hash.label }</p>
          }

          <div className="bg-white rounded-circle shadow d-flex justify-content-center align-items-center" style={{ height: '20em', width: '20em', padding: '3em' }}>
            <img src={ hash.image } alt="prize-1" className="w-100 h-100" style={{objectFit: 'contain'}} />
          </div>

          <p className="font-nunito bg-purple text-white fweight-800 font-size-7 rounded-pill py-2 shadow position-absolute w-em-30" style={{ marginTop: '-0.5em', marginLeft: '1em' }}>{ hash.name }</p>
        </div>
      )
    }));
  }, [prizes]);

// Display selected prize.
  const [startSlotSound1]                   = useState(new Audio(impossibru));
  const [startSlotSound2]                   = useState(new Audio(spinningSound));
  const [confetti, setConfetti]             = useState(false);
  const [displayedPrize, setDisplayedPrize] = useState(<div></div>);
  const [audio1]                            = useState(new Audio(dingSound));
  const [hasPrizeRange, setHasPrizeRange]   = useState(false);
  const [prizeRange, setPrizeRange]         = useState(0);
  const [mainPrizeTimeInSecond]             = useState(44);

  // First & Second Prize only.
  const startSlot   = () => {
    const startBtn  = document.querySelector('.scroll-container div.clickable');
    startBtn.classList.add('d-none');
    const slot      = document.querySelector('.scroll-text');
    slot.classList.remove('d-none');
    slot.classList.add('scroll-text-animation');
    startSlotSound1.play();

    setTimeout(() => {
      setConfetti(true);
    }, mainPrizeTimeInSecond*1000); // 44 seconds
  }

  const startThirdPrizeSlot = () => {
    const startBtn  = document.querySelector('.scroll-container div.clickable');
    startBtn.closest('.parent').classList.add('d-none');
    const slots     = document.querySelectorAll('.scroll-text');

    slots.forEach((slot, index) => {
      slot.closest('.parent').classList.remove('d-none');

      setTimeout(() => {
        slot.classList.add('scroll-text-animation');
      }, index * 500);
    });

    startSlotSound1.play();

    setTimeout(() => {
      setConfetti(true);
    }, 44000);
  }

  const selectGroupRange = (group) => {
    setHasPrizeRange(true);
    setPrizeRange(group);
  }

  const startConsolationPrizeSlot = (winners, hash) => {
    const startBtn  = document.querySelector('.scroll-container div.clickable');
    startBtn.classList.add('d-none');
    const slot      = document.querySelector('.scroll-text');
    slot.classList.remove('d-none');
    slot.classList.add('scroll-text-animation-short');
    startSlotSound2.play();
    let rangeNumber = hash.range_number;

    setTimeout(() => {
      setConfetti(true);
      slot.closest('.scroll-container').classList.add('d-none');
      document.querySelector('.preshrink').classList.add('shrink-image');

      let winnerRangeIndex = Math.floor(selectedPrize.number_of_prize/selectedPrize.range_number)
      if(prizeRange === winnerRangeIndex-1){
        rangeNumber += hash.number_of_prize-(rangeNumber*winnerRangeIndex)
      }

      const startIndex  = prizeRange * rangeNumber;
      const endIndex    = (prizeRange + 1) * rangeNumber;
      const winnerRange = winners.slice(startIndex, endIndex);
      winnerRange.forEach((hash, index) => {
        let parent  = document.createElement('div');
        let h2      = document.createElement('h2');
        let child   = document.createElement('div');
        parent.classList.add('col-4');
        h2.classList.add('winner-name', 'font-nunito', 'winner-box', 'shadow', 'fweight-600');
        child.classList.add('contestant-container', 'font-size-2', 'px-4');
        child.append(`${ startIndex + index + 1 }. (${ hash.id }) ${ hash.name }`);
        h2.append(child);
        parent.append(h2);
        const delay = index * 333; // 0.333 seconds

        setTimeout(() => {
          document.getElementById('winners-container').append(parent);

          if (index % 3 === 0) {
            // audio1.play();
            parent.scrollIntoView({
              behavior: 'smooth'
            });
          }
        }, delay);
      });
    }, 4200);
  }

  const shuffleArray = (array) => {
    let newArray = [...array];

    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }

    return newArray;
  }

  useEffect(() => {
    let prizeList;
    let bigNameList;
    if (selectedPrize) {
      if(selectedPrize.is_consolation){
        bigNameList = randomContestants.concat(randomContestants).concat(randomContestants).concat(randomContestants).concat(randomContestants);
      }else{
        bigNameList = randomMainContestants.concat(randomMainContestants).concat(randomMainContestants).concat(randomMainContestants).concat(randomMainContestants);
      }

      if(bigNameList.length > mainPrizeTimeInSecond*10){
        let roundNumber = Math.round(mainPrizeTimeInSecond*10/100)*100
        bigNameList = bigNameList.filter(function(x,index){
          return index < roundNumber
        })
      }

      if (selectedPrize.is_consolation) {
        const shuffledList      = shuffleArray(bigNameList);
        const nameList          = shuffledList.concat([shuffledList[0]]);
        const rangeArr          = [...Array(Math.floor(selectedPrize.number_of_prize/selectedPrize.range_number))].map((_,i) => i+1);

        const prizeRange        = rangeArr.map((group, index) => {
          let numberOfWinnersRange = selectedPrize.range_number;
          if(rangeArr.length-1 === index){
            numberOfWinnersRange += selectedPrize.number_of_prize-(selectedPrize.range_number*(index+1));
          }
          return <h2 key={ index } className="winner-name font-nunito winner-box shadow fweight-600 w-em-30 mx-2" style={{ zIndex: '2', fontSize: '1.4em' }}>
            <div className="clickable" onClick={() => selectGroupRange(index) }>Draw {numberOfWinnersRange} Winners</div>
          </h2>
        });

        const displayedNameList = nameList.map((hash, index) => {
          return <div key={ index } className="contestant-container font-size-2 px-4">({ hash.id }) { hash.name }</div>
        });

        prizeList = <div>
          <h2 className="font-quantico text-primary mb-2 main-title" style={{ fontSize: '2em' }}>{ selectedPrize.label }</h2>
          <img src={ selectedPrize.image } alt="prize" className="mb-6 preshrink selected-prize-img" />

          <div className="d-flex justify-content-center" data-displayed={ !hasPrizeRange } style={{ width: '100vw' }}>
            { prizeRange }
          </div>

          <div className="d-flex justify-content-center" data-displayed={ hasPrizeRange }>
            <h2 className="scroll-container font-nunito winner-box shadow fweight-600 w-em-40 mx-auto" style={{margin: 'auto'}}>
              <div className="clickable" onClick={() => startConsolationPrizeSlot(theWinners, selectedPrize) }>Start</div>
              <div className="scroll-text d-none">{ displayedNameList }</div>
            </h2>
          </div>

          <div id="list-of-winners" className="px-4">
            <div id="winners-container" className="row">
            </div>
          </div>
        </div>
      } else if (selectedPrize.number_of_prize > 1 && !selectedPrize.is_consolation) {
        let _arr = [...Array(selectedPrize.number_of_prize)].map((_,i) => i);
        const thirdPrizes = _arr.map((i) => {
          const shuffledList      = shuffleArray(bigNameList);
          let nameList            = [];

          if (theWinners.length > 0) {
            nameList              = shuffledList.concat([theWinners[i]]);
          }

          const displayedNameList = nameList.map((hash, index) => {
            return <div key={ index } className="contestant-container font-size-2 px-4">({ hash.id }) { hash.name }</div>
          });

          return <div key={ i } className="col-4">
            <img src={ selectedPrize.image } alt="prize" className="mb-6 w-25" />

            <h2 className="scroll-container font-nunito winner-box shadow fweight-600 clickable parent d-none">
              <div className="scroll-text">
                { displayedNameList }
              </div>
            </h2>
          </div>
        });

        prizeList = <div>
          <h2 className="font-quantico text-primary mb-2 main-title ">{ selectedPrize.label }</h2>
          <div className="row px-4" style={{ width: '100vw' }}>{ thirdPrizes }</div>

          <div className="d-flex justify-content-center parent">
            <h2 className="scroll-container font-nunito winner-box shadow fweight-600 w-em-20 mx-auto">
              <div className="clickable" onClick={() => startThirdPrizeSlot() }>Start</div>
            </h2>
          </div>
        </div>
      } else {
        const nameList          = bigNameList.concat(theWinners);

        const displayedNameList = nameList.map((hash, index) => {
          return <div key={ index } className="contestant-container font-size-2 px-4">({ hash.id }) { hash.name }</div>
        });

        prizeList = <div>
          <h2 className="font-quantico text-primary mb-2 main-title">{ selectedPrize.label }</h2>
          <img src={ selectedPrize.image } alt="prize" className="mb-6 selected-prize-img" />

          <h2 className="scroll-container font-nunito winner-box shadow fweight-600 w-em-40 mx-auto">
            <div className="clickable" onClick={() => startSlot() }>Start</div>
            <div className="scroll-text d-none">{ displayedNameList }</div>
          </h2>
        </div>
      }

      setDisplayedPrize(prizeList);
    }
  }, [selectedPrize, theWinners, hasPrizeRange]);

return (
  <div>
    <SignOut showSignOut={ !showIndividual } backNavigation={() => backToList() } />
    <Confetti width={ width } height={ height } numberOfPieces={ 5000 } recycle={ false } run={ confetti } />

    <div className="text-center" data-displayed={ showPrizes }>
      <div className="d-flex align-items-center justify-content-center" data-displayed={ showFirstLoader }>
        <span className="text-white pe-2" style={{ fontSize: '2em' }}>LOADING</span> <div className="loader"></div>
      </div>

      <div className="d-flex justify-content-between" data-displayed={ !showFirstLoader } style={{flexWrap: "wrap", paddingLeft: "5em", paddingRight: "5em"}}>
        { displayedPrizes }
      </div>

      <ul className="text-white position-fixed fweight-700 font-size-8" style={{ bottom: '1em', left: '1em' }}>
        <li>CLICK ANY TO START</li>
      </ul>
    </div>


    <div className="text-center" data-displayed={ showIndividual }>
      <div className="d-flex align-items-center justify-content-center" data-displayed={ showLoader }>
        <span className="text-white pe-2" style={{ fontSize: '2em' }}>LOADING</span> <div className="loader"></div>
      </div>

      <div data-displayed={ !showLoader }>
        { displayedPrize }
      </div>
    </div>
  </div>
);
}