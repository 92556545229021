import React              from 'react';
import ReactDOM           from 'react-dom';
import * as Sentry        from '@sentry/react';
import { Integrations }   from '@sentry/tracing';
import { BrowserRouter }  from 'react-router-dom';
import App                from './App';
import AuthService        from './services/auth.service';

Sentry.init({
  dsn:          'https://d0f0f31bc72d44959063252aef5920ca@o1069318.ingest.sentry.io/6257634',
  integrations: [new Integrations.BrowserTracing()],
  environment:  process.env.REACT_APP_ENVIRONMENT,
  enabled:      process.env.REACT_APP_ENVIRONMENT !== 'development',

  initialScope: {
    user: AuthService.getCurrentUserSentryParams(),
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.0
});

ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  document.getElementById('root')
);