import React                          from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import './index.scss';
import AuthService                    from './services/auth.service';

// Components
import Landing                        from './components/Landing/Landing';
import Login                          from './components/Login/Login';

// Background
import BgLogin                        from 'assets/images/tnb-login-bg.svg';
import BgLanding                      from 'assets/images/landing-bg.svg';

function App() {
  // console.log({ REACT_APP_ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT });
  let currentUser = AuthService.getCurrentUser();
  let params      = useLocation();
  AuthService.getCurrentUserSentryParams();

  if (!currentUser && params.pathname !== '/login') {
    window.location.replace('/login');
  }

  const bgImage = params.pathname === '/login' ? BgLogin : BgLanding;

  return (
    <div className="App">
      <div id="main-body" style={{ backgroundImage: `url(${ bgImage })` }}>
        <Switch>
          <Route exact path="/login"             component={ Login } />
          <Route exact path="/"                  component={ Landing } />
        </Switch>
      </div>
    </div>
  );
}

export default App;