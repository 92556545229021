import React          from 'react';
import AuthService    from 'services/auth.service';
import { useCookies } from 'react-cookie';
import BackBtn        from 'assets/images/back-btn.svg';

export default function SignOut(props) {
  // Logging out.
  const [cookies,, removeCookie] = useCookies(['AuthSession']);

  const logOut = () => {
    if (props.showSignOut) {
      if (cookies && cookies.AuthSession) {
        removeCookie('AuthSession');
      }

      AuthService.logout();
      window.location.replace('/login');
    } else {
      props.backNavigation();
    }
  };

  return (
    <div className="font-manrope text-black clickable fweight-600 position-absolute py-2 d-flex align-items-center" style={{ top: '1.5em', right: '2em' }} onClick={ logOut }>
      <img src={ BackBtn } alt="sign-out" className="me-3" />
      <p className="mb-0">{ props.showSignOut ? 'Sign Out' : 'Back' }</p>
    </div>
  );
}