import axios from 'axios';
import authHeader from 'services/auth-header';
const API_URL = process.env.REACT_APP_API_ENDPOINT;

class LuckyDrawService  {
  loginInfo() {
    return axios
      .get(API_URL, { headers: authHeader() })
      .then(response => {
        return response.data;
      }
    );
  }

  contestants(id, is_main) {
    return axios
      .get(API_URL + `contestants?id=${ id }&per_page=100&page=1&is_main=${is_main}`, { headers: authHeader() })
      .then(response => {
        return response.data;
      }
    );
  }

  prizes(id ) {
    return axios
      .get(API_URL + `prizes?id=${id}`, { headers: authHeader() })
      .then(response => {
        return response.data;
      }
    );
  }

  // prize(id) {
  //   return axios
  //     .get(API_URL + 'prize' + id, { headers: authHeader() })
  //     .then(response => {
  //       return response.data;
  //     }
  //   );
  // }

  winners(id, prize_id) {
    return axios
      .get(API_URL + `winners?id=${ id }&prize_id=${ prize_id }`, { headers: authHeader() })
      .then(response => {
        return response.data;
      }
    );
  }
}

export default new LuckyDrawService();