import axios from 'axios';
const API_URL = process.env.REACT_APP_API_ENDPOINT;

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + 'login', { email, password })
      .then(response => {
        if (response.data.auth_token) { localStorage.setItem('user', JSON.stringify(response.data)) }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem('user');
    localStorage.removeItem('random-contestants');
    localStorage.removeItem('random-main-contestants');
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  getCurrentUserSentryParams() {
    var userParams          = { id: null, email: null, employer_id: null, employer_name: null, role: null };
    const userObject        = JSON.parse(localStorage.getItem('user'));

    if (userObject) {
      // Must keep as id and email, as this is tracked by sentry over different issues.
      userParams.id             = userObject.id;
      userParams.email          = userObject.email;
      userParams.employer_id    = userObject.employer_id;
      userParams.employer_name  = userObject.employer_name;
      userParams.role           = userObject.role;
    }

    return userParams;
  }
}

export default new AuthService();